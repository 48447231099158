export default [
  {
    id: 1,
    name: "Yoga",
  },
  {
    id: 2,
    name: "Cooking & Food Photography",
  },
  {
    id: 3,
    name: "Fine Arts",
  },
  {
    id: 4,
    name: "Traveling",
  },
]

export default [
  {
    id: 1,
    name: "English",
    level: "fluent",
  },
  {
    id: 2,
    name: "Telugu",
    level: "native",
  },
  {
    id: 3,
    name: "Hindi",
    level: "fluent",
  },
  {
    id: 4,
    name: "Swedish",
    level: "beginner",
  },
]
